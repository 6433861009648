import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	autoProvisionedSwitcherItemConfluenceDescription: {
		id: 'fabric.atlassianSwitcher.autoProvConfluenceUiUpdateExperimentDescription',
		defaultMessage: 'Document collaboration',
		description:
			'When a user gets auto-provisioned Confluence, this is the text that will be in the description of the new Switcher Item',
	},
	atlassianStart: {
		id: 'fabric.atlassianSwitcher.start',
		defaultMessage: 'Atlassian Start',
		description: 'The text of a link redirecting the user to the Start site',
	},
	atlassianStartAriaLabel: {
		id: 'fabric.atlassianSwitcher.startAriaLabel',
		defaultMessage: 'Atlassian Start opens in a new tab',
		description: 'Aria-label for a link redirecting the user to the Start site',
	},
	switchTo: {
		id: 'fabric.atlassianSwitcher.switchTo',
		defaultMessage: 'Switch to',
		description:
			'In a context in which users are able to switch between products, this text is the title of the category displaying the products the user is able to switch to.',
	},
	switchToTooltip: {
		id: 'fabric.atlassianSwitcher.switchToTooltip',
		defaultMessage: 'Switch to…',
		description:
			'This text appears as a tooltip when a user hovers over the atlassian switcher icon before clicking on it.',
	},
	yourApps: {
		id: 'fabric.atlassianSwitcher.yourApps',
		defaultMessage: 'Your apps',
		description:
			'In a context in which users are able to switch between products, this text is the title of the category displaying the products the user is able to switch to.',
	},
	yourAppsTooltip: {
		id: 'fabric.atlassianSwitcher.yourAppsTooltip',
		defaultMessage: 'Your apps…',
		description:
			'This text appears as a tooltip when a user hovers over the atlassian switcher icon before clicking on it.',
	},
	switchSitesOrAppsTooltip: {
		id: 'fabric.atlassianSwitcher.switchSitesOrAppsTooltip',
		defaultMessage: 'Switch sites or apps',
		description:
			'This text appears as a tooltip when a user hovers over the atlassian switcher icon before clicking on it.',
	},
	jwmInactiveSwitcherWithJSWItemDescription: {
		id: 'fabric.atlassianSwitcher.jwmInactiveSwitcherWithJSWItemDescription',
		defaultMessage: 'Manage business projects, free with Jira Software',
		description:
			'Description when a user has never used Jira Work Management but Jira Work Management has been activated and they have a Jira Software License',
	},
	jwmInactiveSwitcherWithDefaultItemDescription: {
		id: 'fabric.atlassianSwitcher.jwmInactiveSwitcherWithDefaultItemDescription',
		defaultMessage: 'Manage projects for business teams',
		description:
			'Description when a user has never used Jira Work Management but Jira Work Management has been activated',
	},
	organisationsLabel: {
		id: 'fabric.atlassianSwitcher.organisationsLabel',
		defaultMessage: 'Organisations',
		description: 'The label of organisation tab list',
	},
	switcherModalLabel: {
		id: 'fabric.atlassianSwitcher.modalLabel',
		defaultMessage: 'Atlassian App Switcher',
		description: 'The label of the switcher modal',
	},
	resizeBarLabel: {
		id: 'fabric.atlassianSwitcher.resizeBarLabel',
		defaultMessage: '"Expand" or "Collapse" depending on state',
		description: 'The label of the resize bar',
	},
	resizeBarTooltipCollapsed: {
		id: 'fabric.atlassianSwitcher.resizeBarTooltipCollapsed',
		defaultMessage: 'Drag to resize{separator}Press to expand',
		description: 'The label of the resize bar tooltip when in collapsed state',
	},
	resizeBarTooltipExpanded: {
		id: 'fabric.atlassianSwitcher.resizeBarTooltipExpanded',
		defaultMessage: 'Drag to resize{separator}Press to collapse',
		description: 'The label of the resize bar tooltip when in expanded state',
	},
	resizeBarValueTextLabel: {
		id: 'fabric.atlassianSwitcher.resizeBarValueTextLabel',
		defaultMessage: 'Width',
		description: 'The value text label of the resize bar',
	},
	joinStatusText: {
		id: 'fabric.atlassianSwitcher.joinStatusText',
		defaultMessage: 'Join',
		description: 'The text displayed alongside a product to indicate the product is joinable.',
	},
	instantAccessStatusText: {
		id: 'fabric.atlassianSwitcher.instantAccessStatusText',
		defaultMessage: 'Instant access',
		description: 'The text displayed alongside a product to indicate the product is joinable.',
	},
	joinTeamStatusText: {
		id: 'fabric.atlassianSwitcher.joinTeamStatusText',
		defaultMessage: 'Join team',
		description: 'The text displayed alongside a product to indicate the product is joinable.',
	},
	jpdDescriptionText: {
		id: 'fabric.atlassianSwitcher.jpdDescriptionText',
		defaultMessage: 'Prioritize and plan new ideas',
		description: 'The description displayed for Jira Product Discovery.',
	},
	confluenceDescriptionText: {
		id: 'fabric.atlassianSwitcher.confluenceDescriptionText',
		defaultMessage: 'Document and collaborate',
		description: 'The description displayed for Confluence.',
	},
	jiraDescriptionText: {
		id: 'fabric.atlassianSwitcher.jiraDescriptionText',
		defaultMessage: 'Plan and track project delivery',
		description: 'The description displayed for Jira.',
	},
	jsmDescriptionText: {
		id: 'fabric.atlassianSwitcher.jsmDescriptionText',
		defaultMessage: 'Automate and deliver IT support',
		description: 'The description displayed for Jira Service Management.',
	},
	requestAccessStatusText: {
		id: 'fabric.atlassianSwitcher.requestAccessStatusText',
		defaultMessage: 'Request access',
		description:
			'The text displayed alongside a product to indicate the user can request access for the product.',
	},
	requestSentStatusText: {
		id: 'fabric.atlassianSwitcher.requestSentStatusText',
		defaultMessage: 'Request sent',
		description:
			'The text displayed alongside a product to indicate the user has requested access to the product.',
	},
	requestAccessPendingRequestFlagTitle: {
		id: 'fabric.atlassianSwitcher.successFlag.title',
		defaultMessage: 'Access request sent to Admin',
		description:
			'Title shown when user has requested access to a product but the request is pending.',
	},
	requestAccessPendingRequestFlagDescription: {
		id: 'fabric.atlassianSwitcher.successFlag.description',
		defaultMessage: "You'll get an email once it's approved",
		description:
			'Description shown when user has requested access to a product but the request is pending',
	},
	requestAccessPendingRequestFlagErrorTitle: {
		id: 'fabric.atlassianSwitcher.errorFlag.title',
		defaultMessage: `We couldn't add you to {product}`,
		description:
			'Title shown when user has requested access to a product but the request has failed.',
	},
	requestAccessPendingRequestFlagErrorDescription: {
		id: 'fabric.atlassianSwitcher.errorFlag.description',
		defaultMessage:
			"Try again in a few minutes. If you still aren't able to join, <a>contact support</a>.",
		description:
			'Description shown when user has requested access to a product but the request is pending',
	},
});

export default messages;
