import { useMemo } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import type { Edition, Product } from '../types';

export type Config = {
	/**
	 * Skips all edition awareness experiences as these users should not see it at all
	 */
	shouldSkip?: boolean;
	/**
	 * The button includes context around how many licensed users are assigned to the product.
	 */
	warnOnUserLimit?: boolean;
	/**
	 * The button includes warning icon if site is over the user or storage limit and on button click a dialog is shown.
	 */
	isStandardTrialsUserAndStorageLimitBreakersAAEnabled?: boolean;
	/**
	 * The button shows only for qualified JSW, JSM, Confluence site admins.
	 */
	isPaidStandard?: boolean;
};

/**
 * Config is in code to ensure that it's executed at run time (SSR will result in all static fg calls to be false).
 * Using a hook to ensure it's memoised.
 */
export function useConfig(product: Product, edition: Edition) {
	return useMemo(() => {
		if (product === 'jira-product-discovery') {
			if (edition === 'free') {
				return {
					shouldSkip: fg('jpd_disable_edition_awareness'),
					warnOnUserLimit: fg('new_ea_jira_product_discovery_free'),
					shouldCheckBillingAdmin: false,
				};
			} else if (edition === 'standard' || edition === 'premium') {
				return {
					shouldSkip: fg('jpd_disable_edition_awareness'),
				};
			}
		}

		if (product === 'jira') {
			if (edition === 'free') {
				return {
					shouldCheckBillingAdmin: false,
					warnOnUserLimit: fg('new_ea_jira_free_modal_refresh'),
				};
			}
			if (edition === 'standard') {
				return {
					isStandardTrialsUserAndStorageLimitBreakersAAEnabled: fg(
						'jira_edition_awareness_standard_trials_aa_gate',
					),
					isPaidStandard: fg('edition_awareness_upsell_jira_fg'),
				};
			}
		}

		if (product === 'jira-service-management') {
			if (edition === 'free') {
				return { shouldCheckBillingAdmin: false };
			}
			if (edition === 'standard') {
				return {
					isPaidStandard: fg('edition_awareness_upsell_jsm_fg'),
				};
			}
		}

		if (product === 'confluence') {
			if (edition === 'free') {
				return { shouldCheckBillingAdmin: false };
			}
			if (edition === 'standard') {
				return {
					isStandardTrialsUserAndStorageLimitBreakersAAEnabled: fg(
						'connie_edition_awareness_standard_trials_aa_gate',
					),
					isPaidStandard:
						fg('edition_awareness_upsell_confluence_aa_fg') ||
						fg('edition_awareness_standard_upsell_confluence_ab_fg'),
				};
			}
		}

		// Default return for other products and editions
		return {};
	}, [product, edition]);
}
