import type { Product } from '../types';

type ApplicationKey = 'JIRA_SOFTWARE' | 'JIRA_PRODUCT_DISCOVERY' | 'JIRA_SERVICE_DESK';

// We're explicitly excluding 'confluence' from this mapping as it's handled separately
const PRODUCT_TO_JIRA_APPLICATION_KEY: Partial<Record<Product, ApplicationKey>> = {
	['jira']: 'JIRA_SOFTWARE',
	['jira-product-discovery']: 'JIRA_PRODUCT_DISCOVERY',
	['jira-service-management']: 'JIRA_SERVICE_DESK',
};

export const getJiraApplicationKey = (product: Product): ApplicationKey | undefined => {
	return PRODUCT_TO_JIRA_APPLICATION_KEY[product];
};
