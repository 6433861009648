import type { Product } from '../types';

type ProjectTypeKey = 'PRODUCT_DISCOVERY' | 'SERVICE_DESK' | 'SOFTWARE';

// We're explicitly excluding 'confluence' from this mapping as it's handled separately
const PRODUCT_TO_JIRA_PROJECT_TYPE_KEY: Partial<Record<Product, ProjectTypeKey>> = {
	['jira']: 'SOFTWARE',
	['jira-product-discovery']: 'PRODUCT_DISCOVERY',
	['jira-service-management']: 'SERVICE_DESK',
};

export const getJiraProjectTypeKey = (product: Product): ProjectTypeKey | undefined => {
	return PRODUCT_TO_JIRA_PROJECT_TYPE_KEY[product];
};
