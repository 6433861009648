/**
 * @generated SignedSource<<737fb44f72caefa1d79edf98105e0f4b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type audienceAndTiming_AtlassianEditionAwareness$data = {
  readonly " $fragmentSpreads": FragmentRefs<"actionsAndContent_AtlassianEditionAwareness">;
  readonly " $fragmentType": "audienceAndTiming_AtlassianEditionAwareness";
};
export type audienceAndTiming_AtlassianEditionAwareness$key = {
  readonly " $data"?: audienceAndTiming_AtlassianEditionAwareness$data;
  readonly " $fragmentSpreads": FragmentRefs<"audienceAndTiming_AtlassianEditionAwareness">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "audienceAndTiming_AtlassianEditionAwareness",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "actionsAndContent_AtlassianEditionAwareness"
    }
  ],
  "type": "Query"
};

(node as any).hash = "2794b5293bc67ae9c19c73adbc0bc9f2";

export default node;
