import type { Edition } from '../types';

import type { Config } from './use-config';

/**
 * Gives the next highest trial offering name, if available.
 */
export const getTrialOfferingName = (edition: Edition, config: Config): Edition => {
	if (edition === 'standard' && config.isPaidStandard) {
		return 'premium';
	}

	return 'standard';
};
