import React, { type ReactNode, useMemo } from 'react';

import {
	RelayEnvironmentProvider as DefaultProvider,
	type IEnvironmentProvider,
	useRelayEnvironment,
} from 'react-relay';
import { Environment, type IEnvironment } from 'relay-runtime';

import { getDefaultRelayEnvironmentConfig } from '@atlassian/relay-environment-config';

const environment: IEnvironment = new Environment(getDefaultRelayEnvironmentConfig({}));

export const RelayEnvironmentProvider = ({ children }: { children: ReactNode }) => {
	return <DefaultProvider environment={environment}>{children}</DefaultProvider>;
};

export function useRelayEnvironmentProvider(): IEnvironmentProvider<{}> {
	const environment = useRelayEnvironment();

	return useMemo(
		() => ({
			getEnvironment() {
				return environment;
			},
		}),
		[environment],
	);
}
