import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

import parameters, {
	type JiraProjectType,
} from './__generated__/freeToStandardRefreshModalEditionAwarenessQuery.graphql';

export type EntryPointParams = {
	cloudId: string;
	hamsProductKey: string;
	projectType: JiraProjectType;
	chargeElement: string;
};

export const entryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "@atlaskit-internal_edition-awareness-free-to-standard-refresh-modal" */ './free-to-standard-refresh-modal'
			),
		{
			moduleId: 'edition-awareness-free-to-standard-refresh-modal',
		},
	),
	getPreloadProps: ({ cloudId, hamsProductKey, projectType, chargeElement }: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			tenantContexts: {
				parameters,
				variables: {
					cloudId,
					hamsProductKey,
					chargeElement,
					projectType,
				},
			},
		},
	}),
});
