import React, { useMemo } from 'react';

import { useOnDismissDialog } from '../../../analytics/use-on-dismiss-dialog';
import { useEditionAwareness } from '../../../services/edition-awareness-context';
import { getChargeElementKey } from '../../../services/get-charge-element-key';
import { getJiraApplicationKey } from '../../../services/get-jira-application-key';
import { getJiraProjectTypeKey } from '../../../services/get-jira-project-type-key';
import { getHamsKey } from '../../../services/product-to-hams-key';
import { ModalEntryPoint } from '../shared/modal-entry-point';

import { DIALOG_CONFIG } from './dialog-config';
import { entryPoint, EntryPointParams } from './entrypoint';

export function FreeToStandardDialog() {
	const { product, cloudId } = useEditionAwareness();

	const jiraApplicationKey = getJiraApplicationKey(product);
	const projectType = getJiraProjectTypeKey(product);

	if (!jiraApplicationKey || !projectType) {
		throw new Error('Tried to render free to standard dialog for unsupported product');
	}

	const entryPointParams: EntryPointParams = useMemo(
		() => ({
			cloudId,
			hamsProductKey: getHamsKey(product),
			chargeElement: getChargeElementKey(product),
			jiraApplicationKey,
			projectType,
		}),
		[cloudId, product, projectType, jiraApplicationKey],
	);

	const onClose = useOnDismissDialog({ ...DIALOG_CONFIG });

	const entryPointProps = useMemo(() => ({ onClose }), [onClose]);

	const modalProps = useMemo(() => ({ width: 'x-large', autoFocus: false }), []);

	return (
		<ModalEntryPoint
			entryPointProps={entryPointProps}
			entryPoint={entryPoint}
			entryPointParams={entryPointParams}
			modalProps={modalProps}
		/>
	);
}
