import differenceInDays from 'date-fns/differenceInDays';

/**
 * This is planned for deprecation when EARM-105 is completed to expose the
 * trial days left through AGG, thus removing the need to calculate it locally
 *
 * https://atl-growth.atlassian.net/browse/EARM-105
 */
export function getTrialDaysLeft(trialEndDate?: number | null) {
	if (!trialEndDate) {
		return undefined;
	}

	// +1 day to round up for fractional days
	return differenceInDays(new Date(trialEndDate), new Date()) + 1;
}
