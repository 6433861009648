import React, { createContext, forwardRef, useCallback, useContext, useMemo } from 'react';

import { type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import EditionsIcon from '@atlaskit/icon-lab/core/editions';
import CreditCardIcon from '@atlaskit/icon/core/migration/credit-card--creditcard-filled';
import type { NewIconProps } from '@atlaskit/icon/types';
import WarningIcon from '@atlaskit/icon/utility/warning';
import {
	Anchor,
	type AnchorProps,
	Box,
	Pressable,
	type PressableProps,
	xcss,
} from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	TopNavButton,
	TopNavLinkButton,
} from '@atlassian/navigation-system/experimental/top-nav-button';
import { useHasCustomTheme } from '@atlassian/navigation-system/experimental/use-has-custom-theme';

import { UiViewedEvent } from '../analytics/ui-viewed-event';
import { useAnalytics } from '../analytics/use-analytics';
import { type ButtonContent } from '../services/use-button-content';

export const ButtonContentContext = createContext<ButtonContent | null>(null);

/**
 * Just used internally to pass the content down without the developer needing to worry about it
 */
function useButtonContext() {
	const res = useContext(ButtonContentContext);

	if (!res) {
		throw new Error('useButtonContext without ButtonContentContext value');
	}

	return res;
}

const buttonStyles = xcss({
	borderWidth: 'border.width',
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	borderStyle: 'solid',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius.100',
	paddingInline: 'space.150',
	paddingBlock: 'space.050',
	minHeight: '32px',
	color: 'color.text',
	transitionProperty: 'color, background-color',
	transitionDuration: '200ms',
	display: 'flex',
	gap: 'space.100',
	alignItems: 'center',
	textDecoration: 'none',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		textDecoration: 'none',
		color: 'color.text',
	},

	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
		textDecoration: 'none',
	},
});

const defaultStyles = xcss({
	color: 'color.text.brand',
	borderColor: 'color.border.brand',

	':hover': {
		color: 'color.text.brand',
	},
});

const warningStyles = xcss({
	borderColor: 'color.border.warning',
});

const dangerStyles = xcss({
	borderColor: 'color.border.danger',
});

const dismissibleStyles = xcss({
	color: 'color.text.discovery',
	borderColor: 'color.border.discovery',
	borderTopRightRadius: '0',
	borderBottomRightRadius: '0',

	':hover': {
		color: 'color.text.discovery',
		backgroundColor: 'color.background.accent.purple.subtlest.hovered',
	},

	':active': {
		backgroundColor: 'color.background.accent.purple.subtlest.pressed',
		textDecoration: 'none',
	},
});

const iconStyles = xcss({
	display: 'flex',
	// icon size cannot grow and shrink
	flexGrow: 0,
	flexShrink: 0,
	alignSelf: 'center',
	userSelect: 'none',
});

function Icon({
	appearance,
	icon,
	color,
}: {
	appearance?: ButtonContent['appearance'];
	icon: ButtonContent['icon'];
	/** Used in the top nav when a customer has a custom theme applied */
	color?: NewIconProps['color'];
}) {
	if (icon === 'warning') {
		const defaultColor =
			appearance === 'danger' ? token('color.icon.danger') : token('color.icon.warning');

		return <WarningIcon label="" color={color ?? defaultColor} />;
	}

	if (icon === 'credit-card') {
		return (
			<CreditCardIcon label="" color={color ?? token('color.icon.discovery')} LEGACY_size="small" />
		);
	}

	return <EditionsIcon label="" color={color ?? token('color.icon.discovery')} />;
}

export const EditionAwarenessButton = forwardRef<
	HTMLButtonElement,
	Omit<PressableProps, 'children'>
>(function ({ onClick, ...rest }, ref) {
	const {
		text,
		icon,
		appearance = 'default',
		isDismissible,
		isEducationDiscount,
	} = useButtonContext();

	const hasCustomTheme = useHasCustomTheme();

	const { sendAnalyticsEvent } = useAnalytics();
	const analyticsAttributes = useMemo(
		() => ({
			type: 'button',
			icon,
			appearance,
			isEducationDiscount,
		}),
		[icon, appearance, isEducationDiscount],
	);

	const handleOnClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			onClick?.(e, analyticsEvent);

			const updatedAnalyticsEvent = analyticsEvent.update((payload) => ({
				...payload,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'editionAwareness',
				attributes: analyticsAttributes,
			}));
			sendAnalyticsEvent(updatedAnalyticsEvent);
		},
		[onClick, sendAnalyticsEvent, analyticsAttributes],
	);

	if (hasCustomTheme) {
		// When there's a custom theme applied we just return a button which gets styled by the theme
		return (
			<TopNavButton
				{...rest}
				ref={ref}
				onClick={handleOnClick}
				iconBefore={() => <Icon appearance={appearance} icon={icon} color="currentColor" />}
			>
				{text}
				<UiViewedEvent
					actionSubjectId="editionAwareness"
					actionSubject="button"
					attributes={analyticsAttributes}
				/>
			</TopNavButton>
		);
	}

	return (
		<Pressable
			{...rest}
			ref={ref}
			xcss={[
				buttonStyles,
				appearance === 'default' && defaultStyles,
				appearance === 'warning' && warningStyles,
				appearance === 'danger' && dangerStyles,
				isDismissible && dismissibleStyles,
			]}
			onClick={handleOnClick}
		>
			<Box xcss={iconStyles}>
				<Icon appearance={appearance} icon={icon} />
			</Box>
			{text}
			<UiViewedEvent
				actionSubjectId="editionAwareness"
				actionSubject="button"
				attributes={analyticsAttributes}
			/>
		</Pressable>
	);
});

export const EditionAwarenessLink = forwardRef<HTMLAnchorElement, Omit<AnchorProps, 'children'>>(
	function ({ onClick, ...rest }, ref) {
		const { text, icon, appearance = 'default' } = useButtonContext();

		const hasCustomTheme = useHasCustomTheme();

		const { sendAnalyticsEvent } = useAnalytics();
		const analyticsAttributes = useMemo(
			() => ({
				type: 'link',
				icon,
				appearance,
			}),
			[icon, appearance],
		);

		const handleOnClick = useCallback(
			(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
				onClick?.(e, analyticsEvent);
				const updatedAnalyticsEvent = analyticsEvent.update((payload) => ({
					...payload,
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'editionAwareness',
					attributes: analyticsAttributes,
				}));
				sendAnalyticsEvent(updatedAnalyticsEvent);
			},
			[onClick, sendAnalyticsEvent, analyticsAttributes],
		);

		if (hasCustomTheme) {
			// When there's a custom theme applied we just return a button which gets styled by the theme
			return (
				<TopNavLinkButton
					{...rest}
					ref={ref}
					onClick={handleOnClick}
					iconBefore={() => <Icon appearance={appearance} icon={icon} color="currentColor" />}
				>
					{text}
					<UiViewedEvent
						actionSubjectId="editionAwareness"
						actionSubject="button"
						attributes={analyticsAttributes}
					/>
				</TopNavLinkButton>
			);
		}

		return (
			<Anchor
				{...rest}
				ref={ref}
				xcss={[
					buttonStyles,
					appearance === 'default' && defaultStyles,
					appearance === 'warning' && warningStyles,
					appearance === 'danger' && dangerStyles,
				]}
				onClick={handleOnClick}
			>
				<Box xcss={iconStyles}>
					<Icon appearance={appearance} icon={icon} />
				</Box>
				{text}
				<UiViewedEvent
					actionSubjectId="editionAwareness"
					actionSubject="button"
					attributes={analyticsAttributes}
				/>
			</Anchor>
		);
	},
);
