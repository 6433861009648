import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import IntlProvider from '../intl-provider';
import messages from './messages';

export const SwitchToTooltipText = (
	<IntlProvider>
		<FormattedMessage {...messages.switchSitesOrAppsTooltip} />
	</IntlProvider>
);
