import { defineMessages } from 'react-intl-next';

import { type ProfileProps } from '@atlaskit/atlassian-navigation';
import { type TriggerProps } from '@atlaskit/popup';

export type AccountMenuTriggerProps = Pick<ProfileProps, 'onClick' | 'testId'> &
	TriggerProps & {
		picture: string | undefined;
		email: string | undefined;
		trustPicture?: boolean;
	};

export const messages = defineMessages({
	triggerTooltip: {
		id: 'identity.account-menu.account-menu-popup.skeleton.tooltip',
		defaultMessage: 'Account',
		description: 'Tooltip and alt-text for the account menu item',
	},
});
