import React from 'react';

import { type AccountMenuProps } from '@atlassian/account-menu';

import { type AccountLinks, type AccountLinksParams, useAccountLinks } from '../../hooks';

export type AccountProps = AccountLinksParams & Omit<AccountMenuProps, keyof AccountLinks>;

type AccountInternalProps = AccountProps & { AccountMenu: React.ComponentType<AccountMenuProps> };

export const AccountInternal: React.ComponentType<AccountInternalProps> = ({
	identityEnvironment,
	application,
	continueUrl,
	logOutContinueUrl,
	isLoggedOut,
	disableSwitchAccountLink,
	AccountMenu,
	...props
}) => {
	const links = useAccountLinks({
		email: props.email,
		identityEnvironment,
		application,
		continueUrl,
		logOutContinueUrl,
		isLoggedOut,
		disableSwitchAccountLink,
	});

	return <AccountMenu {...props} {...links} />;
};
