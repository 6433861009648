import React, { type ReactNode, useMemo, useRef, useState } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';

import { type ModalComponent, ModalContext } from './ModalContext';

type Modal = {
	close: () => void;
	component: ModalComponent;
	key: string;
};

const RenderModal = ({ modal }: { modal: Modal }) => {
	const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState<boolean>();
	const [shouldCloseOnEscapePress, setShouldCloseOnEscapePress] = useState<boolean>();
	const Component = modal.component;

	return (
		<Component
			onClose={modal.close}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
			shouldCloseOnEscapePress={shouldCloseOnEscapePress}
			setShouldCloseOnOverlayClick={setShouldCloseOnOverlayClick}
			setShouldCloseOnEscapePress={setShouldCloseOnEscapePress}
		/>
	);
};

export function ModalContextProvider({ children }: { children: ReactNode }) {
	const id = useRef(1);
	const [modals, setModals] = useState<Modal[]>([]);

	const modalContext = useMemo(
		() => ({
			openModal: (component: ModalComponent) => {
				const key = String(id.current++);
				const close = () => {
					setModals((mdls) => mdls.filter((d) => d.key !== key));
				};

				setModals((mdls) => [...mdls, { close, component, key }]);
			},
		}),
		[],
	);

	return (
		<ModalContext.Provider value={modalContext}>
			{children}
			<ModalTransition>
				{modals.map((modal) => (
					<RenderModal key={modal.key} modal={modal} />
				))}
			</ModalTransition>
		</ModalContext.Provider>
	);
}
