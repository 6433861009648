import React from 'react';

import { Profile } from '@atlaskit/atlassian-navigation';
import LogInIcon from '@atlaskit/icon/core/log-in';
import LegacySignInIcon from '@atlaskit/icon/glyph/sign-in';

import { NAVIGATION_CHANNEL } from '../../analytics';

import { type AccountMenuLoggedOutStateProps, logInTooltip } from './index';

export const AccountMenuLoggedOutStateNav3: React.ComponentType<AccountMenuLoggedOutStateProps> = ({
	testId,
	logInLink,
}) => (
	<Profile
		testId={testId}
		href={logInLink}
		onClick={(event, analytics) => {
			analytics.update({ actionSubjectId: 'logInLink' }).fire(NAVIGATION_CHANNEL);
		}}
		tooltip={logInTooltip}
		icon={
			<LogInIcon
				color="currentColor"
				label=""
				LEGACY_size="medium"
				LEGACY_fallbackIcon={LegacySignInIcon}
			/>
		}
	/>
);
