import React from 'react';

import { NavigationAnalyticsContext } from '@atlaskit/analytics-namespaced-context';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

import { ANALYTICS_CONTEXT } from '../../analytics';
import { AccountMenuContent } from '../account-menu-content';
import { AccountMenuIntlProvider } from '../account-menu-intl-provider';
import { type AccountMenuLoggedOutStateProps } from '../account-menu-logged-out-state';
import { AccountMenuPopup } from '../account-menu-popup';
import { type AccountMenuTriggerProps } from '../account-menu-popup/account-menu-trigger';
import { type ThemeColorModes } from '../theme-switcher';

export type AccountMenuProps = {
	/**
	 * A `testId` prop is provided for specified elements, which is a unique
	 * string that appears as a data attribute `data-testid` in the rendered code,
	 * serving as a hook for automated tests.
	 */
	testId?: string;
	/**
	 * The display name of the user, or `undefined` to display a loading state if
	 * the user's profile data hasn't been loaded yet.
	 *
	 * This should be the `name` returned from `/gateway/api/me` or
	 * [/v2/users/{accountId}/unrestricted](https://developer.atlassian.com/platform/identity/rest/v1/api-group-profile-retrieval/#api-v2-users-accountid-get).
	 */
	name: string | undefined;
	/**
	 * The URL to the avatar of the user, or `undefined` to display a loading
	 * state if the user's profile data hasn't been loaded yet.
	 *
	 * This should be the `picture` returned from `/gateway/api/me` or
	 * [/v2/users/{accountId}/unrestricted](https://developer.atlassian.com/platform/identity/rest/v1/api-group-profile-retrieval/#api-v2-users-accountid-get).
	 */
	picture: string | undefined;
	/**
	 * The email address of the user, or `undefined` to display a loading state if
	 * the user's profile data hasn't been loaded yet.
	 *
	 * This should be the `email` returned from `/gateway/api/me` or
	 * [/v2/users/{accountId}/unrestricted](https://developer.atlassian.com/platform/identity/rest/v1/api-group-profile-retrieval/#api-v2-users-accountid-get).
	 */
	email: string | undefined;
	/**
	 * The name of the Organization that manages the user's account.
	 */
	managedBy?: string;
	/**
	 * The link to Atlassian account's manage-profile page:
	 * `https://id.atlassian.com/manage-profile`.
	 *
	 * If the user's email is available, this link should use Atlassian account's
	 * [session synchronization](https://developer.atlassian.com/platform/identity/learning/sso/integrate-with-account-switcher/#synchronize-accounts)
	 * feature to ensure that the user lands on `manage-profile` with the current
	 * account and not another account they are also logged in to.
	 *
	 * Even though this prop is optional, it should always be provided by
	 * products. Only `https://id.atlassian.com/manage-profile` should be omitting
	 * it.
	 */
	manageProfileLink?: string;
	/**
	 * A link to the user's embedded profile page, if supported by the product.
	 * E.g. `https://hello.atlassian.net/people/me`.
	 *
	 * For client-side routing use this with `onViewProfileClick`.
	 */
	viewProfileLink?: string;
	/**
	 * Event triggered when the link to the user's embedded profile page is clicked.
	 * This enables client-side routing when navigating to the embedded profile page.
	 *
	 * The view profile link will only be visible if `viewProfileLink` is provided.
	 */
	onViewProfileClick?: (
		event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
	/**
	 * The link to Atlassian account's account switcher:
	 * `https://id.atlassian.com/login?prompt=select_account`.
	 *
	 * If this is provided, then `logOutLink` should include the `?prompt=none`
	 * query param so that the user logged out directly.
	 */
	switchAccountLink?: string;
	/**
	 * The link to Atlassian account's logout page:
	 * `https://id.atlassian.com/logout`.
	 *
	 * If `switchAccountLink` is provided, then this link should include the
	 * `?prompt=none` query param so that the user logged out directly.
	 */
	logOutLink: string;
	/**
	 * The link to Atlassian account's login page if the user is currently logged
	 * out: `https://id.atlassian.com/login`.
	 *
	 * If this is provided, then only a login button will be shown instead of the
	 * profile icon and account menu.
	 */
	logInLink?: string;
	/**
	 * Event triggered when an option in the ThemeSwitcher is clicked.
	 *
	 * The ThemeSwitcher will only be visible if both `onThemeChange` and `currentTheme` are provided.
	 */
	onThemeChange?: (theme: ThemeColorModes) => void;
	/**
	 * The current theme enabled for the product (eg. 'dark').
	 *
	 * The ThemeSwitcher will only be visible if both `onThemeChange` and `currentTheme` are provided.
	 */
	currentTheme?: ThemeColorModes;
	/**
	 * Add additional menu items using [ADS Menu](https://atlassian.design/components/menu/) components
	 * such as `MenuGroup`, `Section`, `LinkItem`, `ButtonItem`, etc.
	 *
	 * By default, there are no extra menu items.
	 */
	extraMenuItems?: React.ReactNode;

	/**
	 * Trust the provided picture URL and don't attempt to fetch it again. Setting this to tru will ensure
	 * that the provided picture is included in the SSR render.
	 */
	trustPicture?: boolean;
};

type AccountMenuInternalProps = {
	AccountMenuLoggedOutState: React.ComponentType<AccountMenuLoggedOutStateProps>;
	AccountMenuTrigger: React.ComponentType<AccountMenuTriggerProps>;
};

export const AccountMenu: React.ComponentType<AccountMenuProps & AccountMenuInternalProps> = ({
	testId,
	name,
	picture,
	email,
	managedBy,
	manageProfileLink,
	viewProfileLink,
	onViewProfileClick,
	switchAccountLink,
	logOutLink,
	logInLink,
	onThemeChange,
	currentTheme,
	extraMenuItems,
	trustPicture = false,
	AccountMenuLoggedOutState,
	AccountMenuTrigger,
}) => (
	<NavigationAnalyticsContext data={ANALYTICS_CONTEXT}>
		<AccountMenuIntlProvider>
			{logInLink ? (
				<AccountMenuLoggedOutState
					testId={testId && `${testId}--logged-out-state`}
					logInLink={logInLink}
				/>
			) : (
				<AccountMenuPopup
					testId={testId}
					picture={picture}
					trustPicture={trustPicture}
					email={email}
					AccountMenuTrigger={AccountMenuTrigger}
					content={({ onClose: closePopup }) => (
						<AccountMenuContent
							testId={testId && `${testId}--content`}
							closePopup={closePopup}
							name={name}
							picture={picture}
							email={email}
							managedBy={managedBy}
							manageProfileLink={manageProfileLink}
							viewProfileLink={viewProfileLink}
							onViewProfileClick={onViewProfileClick}
							switchAccountLink={switchAccountLink}
							logOutLink={logOutLink}
							onThemeChange={onThemeChange}
							currentTheme={currentTheme}
							extraMenuItems={extraMenuItems}
						/>
					)}
				/>
			)}
		</AccountMenuIntlProvider>
	</NavigationAnalyticsContext>
);
