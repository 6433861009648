import React from 'react';

import { AccountMenuLoggedOutStateNav3 } from '../account-menu-logged-out-state/nav3';
import { AccountMenuTriggerNav3 } from '../account-menu-popup/account-menu-trigger/nav3';

import { AccountMenu, type AccountMenuProps } from './index';

export const AccountMenuNav3 = (props: AccountMenuProps) => {
	return (
		<AccountMenu
			{...props}
			AccountMenuLoggedOutState={AccountMenuLoggedOutStateNav3}
			AccountMenuTrigger={AccountMenuTriggerNav3}
		/>
	);
};
