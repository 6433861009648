import { useEffect, useRef } from 'react';

import { useAnalytics } from './use-analytics';

/**
 * Put at the end of your render tree, will fire a ui viewed event once when first rendered
 * with the specified `actionSubjectId` and `actionSubject`
 */
export function UiViewedEvent({
	actionSubjectId,
	actionSubject,
	attributes,
}: {
	actionSubjectId: string;
	actionSubject: string;
	attributes?: Record<string, unknown>;
}) {
	const { sendAnalyticsEvent } = useAnalytics();
	const hasSent = useRef(false);

	useEffect(() => {
		if (hasSent.current) {
			return;
		}
		sendAnalyticsEvent({
			eventType: 'ui',
			action: 'viewed',
			actionSubject,
			actionSubjectId,
			attributes,
		});
		hasSent.current = true;
	}, [sendAnalyticsEvent, actionSubject, actionSubjectId, attributes]);

	return null;
}
