import React, { type PropsWithChildren } from 'react';

import { ErrorBoundary as BaseErrorBoundary } from 'react-error-boundary';

import { useErrorAnalytics } from '../analytics/use-error-analytics';
import type { Edition, Product } from '../types';

type ErrorBoundaryProps = PropsWithChildren<{
	product: Product;
	edition: Edition;
	id?: string;
}>;

export const ErrorBoundary = ({ children, product, edition, id }: ErrorBoundaryProps) => {
	const handleOnError = useErrorAnalytics({ product, edition, id });

	return (
		<BaseErrorBoundary onError={handleOnError} fallback={<></>}>
			{children}
		</BaseErrorBoundary>
	);
};
