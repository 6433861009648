/**
 * @generated SignedSource<<053d2c07cdceab4da30930a6a3625965>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type actionsAndContent_AtlassianEditionAwareness$data = {
  readonly tenantContexts?: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly latestUsageForChargeElement?: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "actionsAndContent_AtlassianEditionAwareness";
};
export type actionsAndContent_AtlassianEditionAwareness$key = {
  readonly " $data"?: actionsAndContent_AtlassianEditionAwareness$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionsAndContent_AtlassianEditionAwareness">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "chargeElement"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "entitlementFilter"
    },
    {
      "kind": "RootArgument",
      "name": "productKey"
    },
    {
      "kind": "RootArgument",
      "name": "shouldCheckForChargeQuantities"
    },
    {
      "kind": "RootArgument",
      "name": "shouldSkip"
    }
  ],
  "kind": "Fragment",
  "name": "actionsAndContent_AtlassianEditionAwareness",
  "selections": [
    {
      "condition": "shouldSkip",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": [
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "cloudIds.0",
                  "variableName": "cloudId"
                }
              ],
              "kind": "ListValue",
              "name": "cloudIds"
            }
          ],
          "concreteType": "TenantContext",
          "kind": "LinkedField",
          "name": "tenantContexts",
          "plural": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "hamsProductKey",
                  "variableName": "productKey"
                }
              ],
              "kind": "LinkedField",
              "name": "entitlementInfo",
              "plural": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "where",
                      "variableName": "entitlementFilter"
                    }
                  ],
                  "kind": "LinkedField",
                  "name": "entitlement",
                  "plural": false,
                  "selections": [
                    {
                      "condition": "shouldCheckForChargeQuantities",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "chargeElement",
                              "variableName": "chargeElement"
                            }
                          ],
                          "kind": "ScalarField",
                          "name": "latestUsageForChargeElement"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "2e02743bc17e3604e07b55a1ddfa959d";

export default node;
