import React, { type ComponentType, type ReactElement, type ReactNode } from 'react';

import Placeholder from '@atlaskit/react-ufo/placeholder';

export type PlaceholderComponentProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fallback: any;
	name: string;
	children: ReactNode;
};

export type UFOSegmentComponentProps = {
	name: string;
	children: ReactNode;
};

export type Config = {
	PlaceholderComponent: ComponentType<PlaceholderComponentProps>;
	UFOSegmentComponent: ComponentType<UFOSegmentComponentProps>;
	ModalErrorFallback: (props: { error: Error }) => ReactElement<any, any> | null;
};

const DefaultUFOSegmentComponent = ({ children }: UFOSegmentComponentProps) => <>{children}</>;

const entryPointConfig = {
	PlaceholderComponent: Placeholder,
	UFOSegmentComponent: DefaultUFOSegmentComponent,
	ModalErrorFallback: () => null,
};

export function initEntryPointConfig(overrides: Partial<Config>): Config {
	Object.assign(entryPointConfig, overrides);
	return entryPointConfig;
}

export function useEntryPointConfig(): Config {
	return entryPointConfig;
}
