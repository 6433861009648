import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import type { AccountMenuProps } from '../account-menu';

export type AccountMenuLoggedOutStateProps = Pick<AccountMenuProps, 'testId'> &
	Required<Pick<AccountMenuProps, 'logInLink'>>;

export const logInTooltip = (
	<FormattedMessage
		id="identity.account-menu.account-menu-logged-out-state.log-in"
		defaultMessage="Log in"
		description="Tooltip for log in button when user is logged out"
	/>
);
