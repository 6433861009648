import { EditionAwarenessContextType } from './edition-awareness-context';

export function isEligibleForStandardToPremium({
	product,
	config: { isPaidStandard },
	userRoles,
	paidEntitlement,
}: EditionAwarenessContextType): boolean {
	return !!(
		['confluence', 'jira', 'jira-service-management'].includes(product) &&
		isPaidStandard &&
		paidEntitlement?.isInvoiceable &&
		paidEntitlement?.canUpgrade &&
		userRoles?.includes('SITE_ADMIN')
	);
}
