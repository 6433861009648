import type { Product } from '../types';

const PRODUCT_TO_CHARGE_ELEMENT: Record<Product, string> = {
	['confluence']: 'user',
	['jira']: 'user',
	['jira-product-discovery']: 'user',
	['jira-service-management']: 'agent',
};

/**
 * Gets the CCP charge element that we use for the products to know the amount of assigned licenses
 * a customers tenant has.
 */
export const getChargeElementKey = (product: Product): string => {
	return PRODUCT_TO_CHARGE_ELEMENT[product];
};
