import { EditionAwarenessContextType } from './edition-awareness-context';

type StandardTrialsUserAndStorageLimitBreakersExperimentEligibility = {
	context: EditionAwarenessContextType;
	licensedUsers?: number | null;
	storageUsageInGB?: number;
};

export function isEligibleForStandardTrialsUserAndStorageLimitBreakers({
	context,
	licensedUsers,
	storageUsageInGB,
}: StandardTrialsUserAndStorageLimitBreakersExperimentEligibility): boolean {
	const {
		config: { isStandardTrialsUserAndStorageLimitBreakersAAEnabled },
		product,
		isInTrial,
		isInPredunning,
		isInvoiceable,
		canAddPaymentDetails,
		trialDaysLeft,
	} = context;

	const isOnFirstTrialDay = !!(trialDaysLeft && trialDaysLeft === 14);
	const isOverUserLimit = !!(licensedUsers && licensedUsers > 10);
	const isOverStorageUsageLimit = !!(storageUsageInGB && storageUsageInGB > 2);

	if (product === 'confluence') {
		return !!(
			isStandardTrialsUserAndStorageLimitBreakersAAEnabled &&
			(isInTrial || isInPredunning) &&
			isInvoiceable === false &&
			canAddPaymentDetails &&
			!isOnFirstTrialDay &&
			(isOverUserLimit || isOverStorageUsageLimit)
		);
	}

	if (product === 'jira') {
		return !!(
			isStandardTrialsUserAndStorageLimitBreakersAAEnabled &&
			isInTrial &&
			isInvoiceable === false &&
			canAddPaymentDetails &&
			!isOnFirstTrialDay &&
			(isOverUserLimit || isOverStorageUsageLimit)
		);
	}

	return false;
}
