import { useCallback, useState } from 'react';

export function useSpotlight() {
	const [isSpotlightActive, setIsSpotlightActive] = useState(false);

	const handleSetSpotlight = useCallback(
		(isActive: boolean) => {
			setIsSpotlightActive(isActive);
		},
		[setIsSpotlightActive],
	);

	return { isSpotlightActive, handleSetSpotlight };
}
