// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import LightbulbFilledIcon from '@atlaskit/icon/core/migration/lightbulb--lightbulb-filled';
// AFP-2532 TODO: Fix automatic suppressions below
// https://product-fabric.atlassian.net/browse/AFP-2532
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import { colors } from '@atlaskit/theme';
import {
	ButtonTitle,
	ChecklistButton,
	ChecklistButtonWrapper,
	dismissButtonInlineStyles,
} from './styled';
import { injectIntl, type WrappedComponentProps } from 'react-intl-next';
import messages from './messages';
import { type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import CrossCircle from '@atlaskit/icon/core/migration/cross-circle';
import CrossIcon from '@atlaskit/icon/utility/cross';
import Button from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Pressable, xcss } from '@atlaskit/primitives';

const sucessWrapperStyles = xcss({
	width: '26px',
	height: '26px',
	borderRadius: '100%',
	padding: 'space.0',
	// @ts-ignore --https://product-fabric.atlassian.net/browse/DSP-20920
	backgroundColor: token('color.icon.success'),
	color: 'color.text.inverse',
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const discoveryWrapperStyles = xcss({
	width: '26px',
	height: '26px',
	borderRadius: '100%',
	padding: 'space.0',
	// @ts-ignore --https://product-fabric.atlassian.net/browse/DSP-20920
	backgroundColor: token('color.icon.discovery'),
	color: 'color.text.inverse',
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export type ChecklistButtonProps = {
	isOpen: boolean;
	title: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	completed?: boolean;
	// Passing onDismiss functionality will add a dismiss button
	onDismiss?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
} & WrappedComponentProps;

export default injectIntl(
	({ isOpen, title, completed, onClick, onDismiss, intl }: ChecklistButtonProps) => {
		const hasDismissButton = typeof onDismiss === 'function';

		return (
			<ChecklistButtonWrapper isOpen={isOpen} hasDismissButton={hasDismissButton}>
				<ChecklistButton
					completed={Boolean(completed)}
					onClick={onClick}
					hasDismissButton={hasDismissButton}
				>
					<LightbulbFilledIcon
						LEGACY_size="medium"
						color={token('color.icon.inverse', colors.N0)}
						spacing="spacious"
						label=""
					/>
					<ButtonTitle>{title}</ButtonTitle>
				</ChecklistButton>
				{hasDismissButton && (
					<Button
						onClick={onDismiss}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						style={dismissButtonInlineStyles}
						aria-label={intl.formatMessage(messages.dismissButtonAria, {
							title,
						})}
						iconBefore={
							<Box>
								{fg('platform-visual-refresh-icons') ? (
									<Pressable xcss={completed ? sucessWrapperStyles : discoveryWrapperStyles}>
										<CrossIcon color="currentColor" label={''} />
									</Pressable>
								) : (
									<CrossCircle
										label=""
										LEGACY_size="large"
										color={
											completed
												? token('color.icon.success', colors.G500)
												: token('color.icon.discovery', colors.P500)
										}
										LEGACY_secondaryColor={
											completed
												? token('color.background.accent.green.subtlest', colors.G50)
												: token('color.background.accent.purple.subtlest', colors.P50)
										}
										aria-hidden="true"
									/>
								)}
							</Box>
						}
						testId={'checklist-button--dismiss-button'}
					/>
				)}
			</ChecklistButtonWrapper>
		);
	},
);
