/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx, keyframes } from '@compiled/react';

import { token } from '@atlaskit/tokens';

const starAnimation = keyframes({
	'0%': {
		opacity: 0,
		transform: 'rotate(90deg)',
	},
	'10%': {
		opacity: 1,
		transform: 'rotate(0deg)',
	},
});

const chatGradientAnimation = keyframes({
	'0%': {
		opacity: 0,
	},
	'10%': {
		opacity: 1,
	},
	'100%': {
		opacity: 1,
	},
});

const starStyle = css({
	animation: `${starAnimation} 5s ease-in-out infinite alternate`,
	// this is the center point of the star, calculated from looking at the SVG in figma file and get the X and Y pixel coordinate
	transformOrigin: '12px 5px',
});

const chatIconStyle = css({
	animation: `${chatGradientAnimation} 5s ease-in-out infinite alternate`,
});

type Props = {
	showAnimation?: boolean;
	showGradient?: boolean;
	isSelected?: boolean;
	showStaticIcon?: boolean;
};

export const ChatIconAnimated = ({
	showAnimation = true,
	showGradient = true,
	isSelected = false,
	showStaticIcon = false,
}: Props) => {
	// If showStaticIcon is true, return the static icon (icon without animation and gradient + selected state)
	if (showStaticIcon) {
		return (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					{/* the star */}
					<path
						d="M10.4489 3.4504L7.75593 4.49767C7.75381 4.4985 7.75381 4.5015 7.75593 4.50233L10.4489 5.5496C10.4496 5.54986 10.4501 5.55037 10.4503 5.55103L11.4976 8.24401C11.4984 8.24613 11.5014 8.24613 11.5023 8.24401L12.5495 5.55103C12.5498 5.55037 12.5503 5.54986 12.551 5.5496L15.2439 4.50233C15.2461 4.5015 15.2461 4.4985 15.2439 4.49767L12.551 3.4504C12.5503 3.45014 12.5498 3.44963 12.5495 3.44897L11.5023 0.755991C11.5014 0.753866 11.4984 0.753866 11.4976 0.755991L10.4503 3.44897C10.4501 3.44963 10.4496 3.45014 10.4489 3.4504Z"
						stroke={isSelected ? token('color.icon.selected') : token('color.icon.subtle')}
						strokeWidth="1.5"
					/>
					{/* the chat icon */}
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0 3.125C0 1.67525 1.17525 0.5 2.625 0.5H7.5V2H2.625C2.00368 2 1.5 2.50368 1.5 3.125V13.9576L4.05083 12.5H13.375C13.9963 12.5 14.5 11.9963 14.5 11.375V9H16V11.375C16 12.8247 14.8247 14 13.375 14H4.44917L1.12584 15.899C0.624381 16.1856 0 15.8237 0 15.2457V3.125Z"
						fill={isSelected ? token('color.icon.selected') : token('color.icon.subtle')}
					/>
				</g>
			</svg>
		);
	}
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1_47681)">
				<g clipPath="url(#clip1_1_47681)">
					{/* the star */}
					<path
						css={[showAnimation && starStyle]}
						d="M10.4489 3.4504L7.75593 4.49767C7.75381 4.4985 7.75381 4.5015 7.75593 4.50233L10.4489 5.5496C10.4496 5.54986 10.4501 5.55037 10.4503 5.55103L11.4976 8.24401C11.4984 8.24613 11.5014 8.24613 11.5023 8.24401L12.5495 5.55103C12.5498 5.55037 12.5503 5.54986 12.551 5.5496L15.2439 4.50233C15.2461 4.5015 15.2461 4.4985 15.2439 4.49767L12.551 3.4504C12.5503 3.45014 12.5498 3.44963 12.5495 3.44897L11.5023 0.755991C11.5014 0.753866 11.4984 0.753866 11.4976 0.755991L10.4503 3.44897C10.4501 3.44963 10.4496 3.45014 10.4489 3.4504Z"
						stroke={showGradient ? '#1868DB' : token('color.icon.subtle')}
						strokeWidth="1.5"
					/>
					{/* the chat icon */}
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0 3.125C0 1.67525 1.17525 0.5 2.625 0.5H7.5V2H2.625C2.00368 2 1.5 2.50368 1.5 3.125V13.9576L4.05083 12.5H13.375C13.9963 12.5 14.5 11.9963 14.5 11.375V9H16V11.375C16 12.8247 14.8247 14 13.375 14H4.44917L1.12584 15.899C0.624381 16.1856 0 15.8237 0 15.2457V3.125Z"
						fill={token('color.icon.subtle')}
					/>
					{showGradient && (
						<path
							css={[showAnimation && chatIconStyle]}
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0 3.125C0 1.67525 1.17525 0.5 2.625 0.5H7.5V2H2.625C2.00368 2 1.5 2.50368 1.5 3.125V13.9576L4.05083 12.5H13.375C13.9963 12.5 14.5 11.9963 14.5 11.375V9H16V11.375C16 12.8247 14.8247 14 13.375 14H4.44917L1.12584 15.899C0.624381 16.1856 0 15.8237 0 15.2457V3.125Z"
							fill="url(#paint0_linear_1_47681)"
						/>
					)}
				</g>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_1_47681"
					x1="-4"
					y1="9.5"
					x2="18.8442"
					y2="1.55209"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0065FF" />
					<stop offset="0.30" stopColor="#AF59E1" />
					<stop offset="0.924958" stopColor="#FFA900" />
				</linearGradient>
				<clipPath id="clip0_1_47681">
					<rect width="16" height="16" fill="white" />
				</clipPath>
				<clipPath id="clip1_1_47681">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
