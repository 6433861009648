import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

import parameters from './__generated__/dialogEducationalDiscountEditionAwarenessQuery.graphql';

export type EntryPointParams = {
	cloudId: string;
	hamsProductKey: string;
};

const EducationalDiscountEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "@atlaskit-internal_edition-awareness-educational-discount-dialog" */ './dialog'
			),
		{
			moduleId: 'edition-awareness-educational-discount-modal-dialog',
		},
	),
	getPreloadProps: ({ cloudId, hamsProductKey }: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			tenantContexts: {
				parameters,
				variables: {
					cloudId,
					hamsProductKey,
				},
			},
		},
	}),
});

export default EducationalDiscountEntryPoint;
