import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';

import { PLATFORM_EDUCATIONAL_DISCOUNT_DISMISSED } from '../ui/dialogs/educational-discount-modal/constants';

import { EditionAwarenessContextType } from './edition-awareness-context';

export const isEligibleForEduDiscount = (context: EditionAwarenessContextType) => {
	return (
		isFreeEduOrOrgSite(context) &&
		AtlBrowserStorageLocal.getItem(PLATFORM_EDUCATIONAL_DISCOUNT_DISMISSED) !== 'true'
	);
};

export const isFreeEduOrOrgSite = (context: EditionAwarenessContextType) => {
	const { edition, siteType } = context;
	return edition === 'free' && (siteType === 'EDU' || siteType === 'ORG');
};
