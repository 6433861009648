// Please add your Experimental API name below
// You do not need to wait for approval to add to this list

export const RelayExperimentalApis = [
	'ariGraph',
	'boardCardMove',
	'deleteCard',
	'JiraJqlBuilder',
	'SoftwareCardTypeTransitions',
	'jira-releases-v0',
	'JiraDevOps',
	'JiraDevOpsProviders',
	'createCustomFilter',
	'updateCustomFilter',
	'deleteCustomFilter',
	'customFilters',
	'PermissionScheme',
	'JiraIssue',
	'projectStyle',
	'startSprintPrototype',
	'AddIssuesToFixVersion',
	'JiraVersionResult',
	'JiraIssueConnectionJql',
	'JiraFieldOptionSearching',
	'JiraIssueFieldMutations',
	'JiraIssueDevInfoDetails',
	'JiraIssueDevSummaryCache',
	'JiraVersionWarningConfig',
	'JiraReleaseNotesConfiguration',
	'JiraUpdateReleaseNotesConfiguration',
	'ReleaseNotes',
	'ReleaseNotesOptions',
	'DeploymentsFeaturePrecondition',
	'UpdateVersionWarningConfig',
	'UpdateVersionName',
	'UpdateVersionDescription',
	'UpdateVersionStartDate',
	'UpdateVersionReleaseDate',
	'VersionsForProject',
	'RelatedWork',
	'SuggestedRelatedWorkCategories',
	'setIssueMediaVisibility',
	'toggleBoardFeature',
	'DevOpsProvider',
	'DevOpsSummarisedDeployments',
	'JiraProject',
	'DevOpsSummarisedEntities',
	'RoadmapsMutation',
	'RoadmapsQuery',
	'JiraApplicationProperties',
	'JiraIssueSearch',
	'JiraFilter',
	'featureGroups',
	'setBoardEstimationType',
	'devOps',
	'softwareBoards',
	'name',
	'AddRelatedWorkToVersion',
	'RemoveRelatedWorkFromVersion',
	'admins',
	'canAdministerBoard',
	'jql',
	'globalCardCreateAdditionalFields',
	'GlobalTimeTrackingSettings',
	'ReleaseNotesOptions',
	'search-experience',
	'MoveOrRemoveIssuesToFixVersion',
	'compass-beta',
	'JiraIssueSearchStatus',
	'Townsquare',
	'JiraNavigationItems',
	'JiraVersionsForProjects',
	'columnConfigs',
];
