import { useCallback } from 'react';

import type { DismissMenuIntent } from './types';
import { useAnalytics } from './use-analytics';

export const useOnClickDismissMenu = () => {
	const { sendAnalyticsEvent } = useAnalytics();

	return useCallback(
		(dismissMenuIntent: DismissMenuIntent) => {
			sendAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'editionAwarenessDismissMenu',
				attributes: {
					dismissMenuIntent,
				},
			});
		},
		[sendAnalyticsEvent],
	);
};
