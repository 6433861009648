import React, { ReactNode, useEffect, useRef } from 'react';

import { useAnalytics } from '../analytics/use-analytics';

/**
 * Tracks when a user is enrolled in an edition awareness experiment
 */
export function FeatureExposedAnalytics({
	flagKey,
	cohort,
	growthInitiative,
	children,
}: {
	flagKey: string;
	cohort: string;
	/**
	 * This is usually the owning teams name e.g. `transmuters`
	 */
	growthInitiative?: string;
	children?: ReactNode;
}) {
	const { sendAnalyticsEvent } = useAnalytics();
	const hasSent = useRef(false);

	useEffect(() => {
		if (hasSent.current) {
			return;
		}

		sendAnalyticsEvent({
			eventType: 'track',
			action: 'exposed',
			actionSubject: 'feature',
			attributes: {
				flagKey,
				cohort,
				growthInitiative,
			},
		});

		hasSent.current = true;
	}, [sendAnalyticsEvent, flagKey, cohort, growthInitiative]);

	if (!children) {
		return null;
	}

	return <>{children}</>;
}
