/**
 * @generated SignedSource<<64e60a22ab514bb01e4e656b4117eea5>>
 * @relayHash 252f9485f5e5cee5b4ade726e8f4235a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7e60d7a62f44b5b4ddf187f779ba3dddc0dddcd9803cdafb27feed64cad7ac05

import type { ConcreteRequest, Query } from 'relay-runtime';
export type actionsAndContentConfluenceStorageQuery$variables = {
  cloudId: string;
  shouldCheckForStorageUsageConfluence: boolean;
};
export type actionsAndContentConfluenceStorageQuery$data = {
  readonly confluence_storage?: {
    readonly bytesUsed: AGG$Long;
  } | null | undefined;
};
export type actionsAndContentConfluenceStorageQuery = {
  response: actionsAndContentConfluenceStorageQuery$data;
  variables: actionsAndContentConfluenceStorageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldCheckForStorageUsageConfluence"
  }
],
v1 = [
  {
    "condition": "shouldCheckForStorageUsageConfluence",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "concreteType": "ConfluenceStorage",
        "kind": "LinkedField",
        "name": "confluence_storage",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "bytesUsed"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "actionsAndContentConfluenceStorageQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "actionsAndContentConfluenceStorageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7e60d7a62f44b5b4ddf187f779ba3dddc0dddcd9803cdafb27feed64cad7ac05",
    "metadata": {},
    "name": "actionsAndContentConfluenceStorageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e4b1f9631fe7375a9fc71e95d8253d75";

export default node;
