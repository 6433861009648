import { type CacheConfig, Network, type RequestParameters, type Variables } from 'relay-runtime';

import { getAggEndpoint, getRequestBody } from '@atlassian/relay-endpoint';

import { RelayExperimentalApis } from './constants';

function fetchQuery(request: RequestParameters, variables: Variables, cacheConfig: CacheConfig) {
	const endpoint = getAggEndpoint(request, cacheConfig);
	const body = getRequestBody(request, variables);

	return fetch(endpoint, {
		method: 'POST',
		credentials: 'same-origin',
		headers: {
			// Add authentication and other headers here
			'content-type': 'application/json',
			'X-ExperimentalApi': RelayExperimentalApis.join(','),
		},
		body,
	}).then((response) => {
		return response.json();
	});
}

// Create a network layer from the fetch function
export const network = Network.create(fetchQuery);
