/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */

import React, { useCallback, useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { jsx } from '@atlaskit/css';
import Lozenge from '@atlaskit/lozenge';
import { Box, Flex, xcss } from '@atlaskit/primitives';

import type { EditionAwarenessStandardPubTouchpointProps } from './types';
export const messages = defineMessages({
	persistentUpgradeMenuItemStandardToPremiumPlanLabelExperiment: {
		id: 'persistent-upgrade.trial-plan-comparison.section.message.persistent-upgrade.menuitem.freeplan.label',
		defaultMessage: 'Try the Premium plan',
		description: 'Account profile menu item label for free plan.',
	},
	persistentUpgradeMenuItemStandardToPremiumPlanLozengeExperiment: {
		id: 'persistent-upgrade.trial-plan-comparison.section.message.persistent-upgrade.menuitem.freeplan.lozenge',
		defaultMessage: 'FREE 30-DAY TRIAL',
		description: 'Account profile menu item lozenge for free plan',
	},
});
const menuItemContentStyles = xcss({
	display: 'flex',
	flexWrap: 'nowrap',
});
const lozengeContainerStyles = xcss({
	marginTop: 'space.0',
	marginRight: 'space.0',
	marginBottom: 'space.0',
	marginLeft: 'space.100',
});
function EditionAwarenessStandardPubTouchpointImpl({
	product,
	testId,
}: EditionAwarenessStandardPubTouchpointProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'editionAwarenessStandardToPremiumPUB',
				attributes: {
					source: 'EditionAwarenessStandardPubTouchpoint',
					product,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, product]);
	return (
		<Flex xcss={menuItemContentStyles} testId={testId}>
			<FormattedMessage
				{...messages.persistentUpgradeMenuItemStandardToPremiumPlanLabelExperiment}
			/>
			<Box xcss={lozengeContainerStyles}>
				<Lozenge appearance="new">
					<FormattedMessage
						{...messages.persistentUpgradeMenuItemStandardToPremiumPlanLozengeExperiment}
					/>
				</Lozenge>
			</Box>
		</Flex>
	);
}

export function EditionAwarenessStandardPubTouchpoint(
	props: EditionAwarenessStandardPubTouchpointProps,
) {
	const { product } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOnError = useCallback(
		(error: Error) => {
			createAnalyticsEvent({
				eventType: 'operational',
				action: 'failed',
				actionSubject: '@atlassian/edition-awareness.edition-awareness',
				source: 'unknownErrorSource',
				attributes: {
					product,
					edition: 'standard',
					error: error.message,
				},
			}).fire();
		},
		[createAnalyticsEvent, product],
	);
	return (
		<ErrorBoundary fallback={<></>} onError={handleOnError}>
			<EditionAwarenessStandardPubTouchpointImpl {...props} />
		</ErrorBoundary>
	);
}
