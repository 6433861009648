/**
 * @generated SignedSource<<968587b2c0b40106b55a513ecaa388f2>>
 * @relayHash 1c5621a38bb1bf85b583801421f9ca20
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0a44bc18b556b735e999d7db15201dc99c9951915dd862b7049655f5e180fc3a

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraApplicationKey = "JIRA_CORE" | "JIRA_PRODUCT_DISCOVERY" | "JIRA_SERVICE_DESK" | "JIRA_SOFTWARE" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type dialogFreeToStandardEditionAwarenessQuery$variables = {
  chargeElement: string;
  cloudId: string;
  hamsProductKey: string;
  jiraApplicationKey: JiraApplicationKey;
  projectType: JiraProjectType;
};
export type dialogFreeToStandardEditionAwarenessQuery$data = {
  readonly jira: {
    readonly allJiraProjects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly assignableUsers: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly accountId: string;
                readonly name: string;
                readonly picture: AGG$URL;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly attachmentStorageAllowed: AGG$Long | null | undefined;
    readonly attachmentStorageIsUnlimited: boolean | null | undefined;
    readonly attachmentStorageUsed: AGG$Long | null | undefined;
  } | null | undefined;
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly experienceCapabilities: {
          readonly changeOfferingV2: {
            readonly experienceUrl: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly latestUsageForChargeElement: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type dialogFreeToStandardEditionAwarenessQuery = {
  response: dialogFreeToStandardEditionAwarenessQuery$data;
  variables: dialogFreeToStandardEditionAwarenessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chargeElement"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hamsProductKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jiraApplicationKey"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectType"
},
v5 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "hamsProductKey"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "offeringName",
    "value": "Standard"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v9 = {
  "args": [
    {
      "kind": "Variable",
      "name": "chargeElement",
      "variableName": "chargeElement"
    }
  ],
  "kind": "ScalarField",
  "name": "latestUsageForChargeElement"
},
v10 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v11 = [
  {
    "kind": "Variable",
    "name": "applicationKey",
    "variableName": "jiraApplicationKey"
  },
  (v10/*: any*/)
],
v12 = {
  "args": (v11/*: any*/),
  "kind": "ScalarField",
  "name": "attachmentStorageAllowed"
},
v13 = {
  "args": (v11/*: any*/),
  "kind": "ScalarField",
  "name": "attachmentStorageUsed"
},
v14 = {
  "args": (v11/*: any*/),
  "kind": "ScalarField",
  "name": "attachmentStorageIsUnlimited"
},
v15 = [
  (v10/*: any*/),
  {
    "fields": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "types.0",
            "variableName": "projectType"
          }
        ],
        "kind": "ListValue",
        "name": "types"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v17 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v18 = {
  "kind": "ScalarField",
  "name": "picture"
},
v19 = {
  "kind": "ScalarField",
  "name": "name"
},
v20 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v21 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "dialogFreeToStandardEditionAwarenessQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v6/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v7/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": "changeOfferingV2(offeringName:\"Standard\")"
                      }
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "args": (v15/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "allJiraProjects",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v16/*: any*/),
                        "concreteType": "JiraAssignableUsersConnection",
                        "kind": "LinkedField",
                        "name": "assignableUsers",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraAssignableUsersEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "assignableUsers(first:10)"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "dialogFreeToStandardEditionAwarenessQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v6/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      {
                        "args": (v7/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": "changeOfferingV2(offeringName:\"Standard\")"
                      }
                    ]
                  },
                  (v9/*: any*/),
                  (v21/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "args": (v15/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "allJiraProjects",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v16/*: any*/),
                        "concreteType": "JiraAssignableUsersConnection",
                        "kind": "LinkedField",
                        "name": "assignableUsers",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraAssignableUsersEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/),
                                  (v21/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "assignableUsers(first:10)"
                      },
                      (v21/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0a44bc18b556b735e999d7db15201dc99c9951915dd862b7049655f5e180fc3a",
    "metadata": {},
    "name": "dialogFreeToStandardEditionAwarenessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1505e5627b6f6574772868e644e669b9";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
