type BillingCycle = 'annual' | 'monthly';
type Theme = 'light' | 'dark';
type ContentType = 'reverseTrial';

export enum Offering {
	FREE = 'free',
	STANDARD = 'standard',
	PREMIUM = 'premium',
}

export enum Action {
	START_TRIAL = 'startTrial',
	DIRECT_BUY = 'directBuy',
	ADD_PAYMENT = 'addPayment',
}

// type guards
export function isOffering(value: string): value is Offering {
	return Object.values(Offering).includes(value as Offering);
}

export function isAction(value: Action): value is Action {
	return Object.values(Action).includes(value as Action);
}

export function isOfferingAction(value?: OfferingAction | string): value is OfferingAction {
	if (!value || typeof value !== 'object') {
		return false;
	}

	// Must have at least one valid key value pair
	return Object.entries(value).some((item) => {
		const offering = item[0];
		const action = item[1];
		return isOffering(offering) && isAction(action);
	});
}

type BaseConfigurationOptions = {
	[key: string]: string | string[] | OfferingAction | undefined;
};

export type OfferingAction = Record<Offering, Action>;

export type EntitlementTransitionOptions = {
	name: 'entitlementTransition';
	offeringHighlighted?: Offering;
	offeringsShown?: Offering[];
	billingCycle?: BillingCycle;
	theme?: Theme;
	contentType?: ContentType;
	offeringsWithActions?: OfferingAction;
	showProgressNav?: string; // 'true' | 'false'
} & BaseConfigurationOptions;

export type ConfigurationOptions = EntitlementTransitionOptions | BaseConfigurationOptions;

type CompletedEntitlementTransition = {
	name: 'entitlementTransition';
};

type CompletedAddBillingAdmin = {
	name: 'addBillingAdmin';
};

type CompletedGeneric = {
	name: string;
};

type CompletedData = CompletedEntitlementTransition | CompletedAddBillingAdmin | CompletedGeneric;
type MessageData = Record<string, string>;

export type OnCompleteCallback = (data: CompletedData) => void;
export type OnErrorCallback = (error: Error) => void;
export type OnMessageCallback = (message: MessageData) => void;

declare global {
	interface Window {
		cexp_sdk_development?: boolean;
		cexp_sdk?: {
			onComplete: OnCompleteCallback;
			onError: OnErrorCallback;
			onMessage?: OnMessageCallback;
		};
	}
}
