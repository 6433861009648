import { useCallback } from 'react';

import type { DialogAnalyticsParams } from './types';
import { useAnalytics } from './use-analytics';

/**
 * Given `touchpointIntent` `dialogType` `dialogId` will
 * return a callback which fires an editionAwarenessDialogDismissed button clicked event
 */
export const useOnDismissDialog = ({
	touchpointIntent,
	dialogType,
	dialogId,
	attributes,
}: DialogAnalyticsParams) => {
	const { sendAnalyticsEvent } = useAnalytics();

	return useCallback(() => {
		sendAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'editionAwarenessDialogDismissed',
			attributes: {
				touchpointIntent,
				dialogType,
				dialogId,
				...attributes,
			},
		});
	}, [sendAnalyticsEvent, touchpointIntent, dialogType, dialogId, attributes]);
};
