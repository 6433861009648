/**
 * @generated SignedSource<<5031cfef0d3cf30c4b8598c16b386c46>>
 * @relayHash 67d9f2a253783962ce69cf598309a8e9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3ea4bc8ddda8670db6ce8e6f43a905888eed549baaf8c7bbb1e6e38f8e0577ab

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraApplicationKey = "JIRA_CORE" | "JIRA_PRODUCT_DISCOVERY" | "JIRA_SERVICE_DESK" | "JIRA_SOFTWARE" | "%future added value";
export type actionsAndContentJiraStorageQuery$variables = {
  applicationKey: JiraApplicationKey;
  cloudId: string;
  shouldCheckForStorageUsageJira: boolean;
};
export type actionsAndContentJiraStorageQuery$data = {
  readonly jira?: {
    readonly attachmentStorageUsed: AGG$Long | null | undefined;
  } | null | undefined;
};
export type actionsAndContentJiraStorageQuery = {
  response: actionsAndContentJiraStorageQuery$data;
  variables: actionsAndContentJiraStorageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "applicationKey"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldCheckForStorageUsageJira"
},
v3 = [
  {
    "condition": "shouldCheckForStorageUsageJira",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "applicationKey",
                "variableName": "applicationKey"
              },
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              }
            ],
            "kind": "ScalarField",
            "name": "attachmentStorageUsed"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "actionsAndContentJiraStorageQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "actionsAndContentJiraStorageQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "3ea4bc8ddda8670db6ce8e6f43a905888eed549baaf8c7bbb1e6e38f8e0577ab",
    "metadata": {},
    "name": "actionsAndContentJiraStorageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9111c15203d8bef24c8f0a5319a68369";

export default node;
