import type { Product } from '../types';

const ProductKeyToHAMSMap: Record<Product, string> = {
	['confluence']: 'confluence',
	['jira']: 'jira-software',
	['jira-product-discovery']: 'jira-product-discovery',
	['jira-service-management']: 'jira-servicedesk',
};

/**
 * When querying AGG for entitlements we need to pass through the `hamsProductKey` which is
 * a specific key used to identify the product, we're just mapping our product keys to those
 * hams keys for queries
 */
export const getHamsKey = (product: Product): string => {
	return ProductKeyToHAMSMap[product];
};
