import {
	type ConfigurationOptions,
	isAction,
	isOffering,
	isOfferingAction,
	OfferingAction,
} from '../types';

export function appendQueryParams(url: URL, configurationOptions?: ConfigurationOptions): URL {
	if (configurationOptions) {
		const sanitizedConfigurationOptions = sanitizeConfigurationOptions(configurationOptions);

		const existingParams = new URLSearchParams(url.search);

		Object.entries(sanitizedConfigurationOptions).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				existingParams.set(key, value.join(','));
			} else if (isOfferingAction(value)) {
				existingParams.set(
					key,
					Object.entries(value)
						.map((item) => item.join(':'))
						.join(','),
				);
			} else {
				if (value) {
					existingParams.set(key, value);
				}
			}
		});

		url.search = existingParams.toString();
	}

	return url;
}

export function sanitizeConfigurationOptions(options: ConfigurationOptions): ConfigurationOptions {
	const sanitizedOptions: Partial<ConfigurationOptions> = {};

	Object.entries(options).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			// Filter out non-string values and null/undefined elements
			sanitizedOptions[key] = value.filter((item) => typeof item === 'string' && item != null);
		} else if (typeof value === 'string') {
			// Include only string values
			sanitizedOptions[key] = value;
		} else if (isOfferingAction(value)) {
			const filtered = Object.entries(value).filter((item) => {
				const offering = item[0];
				const action = item[1];
				return isOffering(offering) && isAction(action);
			});

			sanitizedOptions[key] = Object.fromEntries(filtered) as OfferingAction;
		}
		// Omit null, undefined, and non-string types
	});

	return sanitizedOptions;
}
