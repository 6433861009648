import React, { useState } from 'react';

import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useCallbackWithAnalytics } from '@atlaskit/analytics-next';
import Popup, { type PopupProps } from '@atlaskit/popup';

import { NAVIGATION_CHANNEL } from '../../analytics';
import type { AccountMenuProps } from '../account-menu';

import type { AccountMenuTriggerProps } from './account-menu-trigger';

export type AccountMenuPopupProps = Pick<AccountMenuProps, 'testId' | 'picture' | 'email'> &
	Pick<PopupProps, 'content'> & {
		trustPicture?: boolean;
		AccountMenuTrigger: React.ComponentType<AccountMenuTriggerProps>;
	};

export const AccountMenuPopup: React.ComponentType<AccountMenuPopupProps> = ({
	testId,
	picture,
	email,
	content,
	trustPicture = false,
	AccountMenuTrigger,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const close = useCallbackWithAnalytics(
		() => setIsOpen(false),
		{
			eventType: UI_EVENT_TYPE,
			action: 'closed',
			actionSubject: 'popup',
			actionSubjectId: 'accountMenu',
		},
		NAVIGATION_CHANNEL,
	);
	const open = useCallbackWithAnalytics(
		() => setIsOpen(true),
		{
			eventType: UI_EVENT_TYPE,
			action: 'opened',
			actionSubject: 'popup',
			actionSubjectId: 'accountMenu',
		},
		NAVIGATION_CHANNEL,
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={close}
			testId={testId}
			placement="bottom"
			trigger={(triggerProps) => (
				<AccountMenuTrigger
					onClick={isOpen ? close : open}
					testId={testId && `${testId}--trigger`}
					picture={picture}
					email={email}
					trustPicture={trustPicture}
					{...triggerProps}
				/>
			)}
			content={content}
		/>
	);
};
