/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { Profile } from '@atlaskit/atlassian-navigation';
import UserAvatarCircleIcon from '@atlaskit/icon/core/migration/person-avatar--user-avatar-circle';
import { token } from '@atlaskit/tokens';

import { useImageState } from '../../../hooks/use-image-state';

import { type AccountMenuTriggerProps, messages } from './index';

const imgStyles = css({
	borderRadius: '50%',
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
});

// Also seems to affect `confluence/next/tools/config/tsconfig.platform.json`
// Looks related to string ref typing
// @ts-ignore: [PIT-1685] Fails in post-office due to backwards incompatibility issue with React 18
export const AccountMenuTriggerNav3: React.ComponentType<AccountMenuTriggerProps> =
	React.forwardRef(({ picture, email, trustPicture = false, ...props }, ref) => {
		const intl = useIntl();
		const state = useImageState(picture || '');

		const tooltip = intl.formatMessage(messages.triggerTooltip);

		return (
			<Profile
				{...props}
				ref={ref}
				tooltip={email || tooltip}
				icon={
					picture && (trustPicture || state === 'loaded') ? (
						<img css={imgStyles} src={picture} alt={tooltip} />
					) : (
						<UserAvatarCircleIcon
							color="currentColor"
							label={tooltip}
							LEGACY_size="medium"
							spacing="spacious"
						/>
					)
				}
			/>
		);
	});
