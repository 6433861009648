import { type ComponentType, createContext } from 'react';

import noop from 'lodash/noop';

export type ModalComponentProps = {
	onClose: () => void;
	shouldCloseOnOverlayClick?: boolean;
	shouldCloseOnEscapePress?: boolean;
	setShouldCloseOnOverlayClick: (shouldCloseOnOverlayClick: boolean) => void;
	setShouldCloseOnEscapePress: (shouldCloseOnEscapePress: boolean) => void;
};

export type ModalComponent = ComponentType<ModalComponentProps>;

export const ModalContext = createContext<{
	openModal: (component: ModalComponent) => void;
}>({
	openModal: noop,
});
